.document-container {
    padding: 20px;
    /* font-family: 'Open Sans', sans-serif; */
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333;
}


.document-container h1 {
    font-size: 36px;
    margin: .67em 0;
}

.document-container h2 {
    font-size: 30px;
    margin-top: 20px;
}

.document-container h3 {
    font-size: 24px;
    margin-top: 20px;
}

.document-container h4 {
    font-size: 18px;
}

.document-container h5 {
    font-size: 14px;
}
.document-container h6 {
    font-size: 12px;
}

.document-container h1, h2, h3, h4, h5, h6 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-weight: 600 !important;
    line-height: 1.1 !important;
    color: inherit !important;
}

.document-container p {
    margin-top: 6px;
    margin-bottom: 6px;
}

.document-container table {
    border-collapse: collapse;
    width: 100%;
    margin: 1em 0;
}

.document-container table td {
    border: 1px solid #d3d3d3;
    padding: 8px;
    min-width: 50px;
}

.document-container table th {
    border: 1px solid #adadad;
    padding: 8px;
    min-width: 50px;
    background-color: #f5f5f5;
    font-weight: bold;
}

.document-container table tr {
    min-height: 2em;
    line-height: 1.5;
}